<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class=" flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-md sm:p-6">
                            <div class="flex items-center justify-center gap-4 border-b pb-4">
                                <div class="flex h-10 w-10 items-center justify-center rounded-full bg-blue-100">
                                    <CloudUploadIcon class="h-5 w-5 text-blue-600" aria-hidden="true" />
                                </div>
                                <div>
                                    <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Upload
                                        Image</DialogTitle>
                                </div>
                            </div>
                            <div class="mt-4 flex flex-col justify-center items-center gap-4">
                                <div class="w-full text-center" :class="{ 'hidden': imageUploading }">
                                    <div class="sm:col-span-3">
                                        <div class="flex mt-2">
                                            <button
                                                class="w-full relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                                                <label for="user-photo"
                                                    class="text-center w-full relative text-sm font-medium text-blue-gray-900 pointer-events-none">
                                                    <span>{{ image ? "Change Image" : "Select Image" }}</span>
                                                    <span class="sr-only">Image</span>
                                                </label>
                                                <input @change="setSelectedImage" id="user-photo" name="user-photo"
                                                    type="file"
                                                    class="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div :class="[imageUploading ? 'block' : 'hidden']" class="flex items-center">
                                    <AnimationSpinner />Uploading Image...
                                </div>
                                <!-- <div class="w-full">
                                    <p class="text-center font-semibold mx-4 mb-0">OR</p>
                                </div>
                                <div
                                    class="w-full h-24 border-4 border-gray-400 text-sm border-dashed p-4 flex items-center justify-center rounded-md">
                                    Drag your image here
                                </div> -->
                                <div><img :src="imagePreview" /></div>
                            </div>
                            <div class="border-y mt-4 py-4">
                                <label for="name" class="block text-sm font-medium text-gray-700">Image Name</label>
                                <div class="mt-1">
                                    <input v-model="imageName" type="text" name="name" id="name"
                                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                        placeholder="e.g. Brand Logo" />
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6 flex flex-col sm:flex-row gap-4">
                                <button type="button"
                                    :class="{ 'disable-btn bg-green-400': !imageName || !image || imageUploading }"
                                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm md:text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                                    @click="imageUpload()">Upload</button>
                                <button type="button" :class="{ 'disable-btn bg-red-400': imageUploading }"
                                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm md:text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                                    @click="closeModal()">Cancel</button>
                            </div>

                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CloudUploadIcon } from '@heroicons/vue/outline'



const tenantId = useActiveTenantId()
const config = useRuntimeConfig();

// Define emits
const emit = defineEmits(["closeUploadImageModal", "uploadImageSuccess"]);
function closeModal() {
    image.value = null
    imagePreview.value = null
    imageName.value = ""
    emit("closeUploadImageModal");
}
function imageUploaded() {
    image.value = null
    imagePreview.value = null
    imageName.value = ""
    emit("uploadImageSuccess");
}

const props = defineProps({
    open: {
        type: Boolean,
        required: true
    }
})

const { open } = toRefs(props)
const imageName = ref("")
const image = ref(null);
const imagePreview = ref(null);
const imageUploading = ref(false)

function setSelectedImage(e) {
    image.value = e.target.files[0];
    const files = e.target.files;
    const filename = files[0].name;
    if (filename.lastIndexOf(".") <= 0) {
        return alert("Please add a valid file");
    }
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
        imagePreview.value = fileReader.result;
    });

    fileReader.readAsDataURL(files[0]);
}

// Get cloudflare image upload URL
const imageUploadUrl = ref("");
const getImageUploadURL = async () => {
    try {
        const data = await wetixFetch(
            `${config.WETIX_SERVICE_API_BASE_URL}/v1/tenant/${tenantId}/get-image-upload-url`,
            { method: "POST" }
        ) as any;

        const { uploadURL } = data;
        imageUploadUrl.value = uploadURL;
    } catch (e) {
        console.log(e);
    }
};

// Upload image to cloudflare using the uploadURL which was returned from the backend
const imageUpload = async () => {
    imageUploading.value = true
    try {
        await getImageUploadURL()
        const url = imageUploadUrl.value;
        const formData = new FormData();
        formData.append("file", image.value);
        const response = await useFetch(url,
            {
                method: "POST",
                body: formData
            }
        ) as any;
        const { result, success } = response.data.value
        console.log(result);
        if (!success) {
            throw createError({
                statusMessage: `Cloudflare API did not return success, ${response.errors}`
            })
        }

        const imageData = {
            result: { ...result },
            imageName: imageName.value
        }

        imageUploaded()
        imageUploading.value = false

        // Post image data to backend
        await wetixFetch(
            `${config.WETIX_SERVICE_API_BASE_URL}/v1/tenant/${tenantId}/upload-tenant-image`,
            {
                method: "POST",
                body: imageData
            }
        ) as any;

    }
    catch (e) {
        console.log(e)
    }
    imageUploading.value = false
}

</script>

<style scoped>
.disable-btn {
    pointer-events: none;
}
</style>